<template>
  <div class="depth-column">
    <ul class="menu-list">
      <li class="menu-item" v-for="(item, index) in items" :key="index"
          :class="{ 'is-active' : index === selectedIndex }">
        <a href="javascript:" class="menu-link" @click.stop="selectOption(index)">
          <span class="text">{{ item[displayKey] }}</span>
        </a>
      </li>
    </ul>
  </div>

  <CommonDeptMenu
      v-if="children && children.length > 0"
      :depth="(depth + 1)"
      :items="children"
      :value-key="valueKey"
      :display-key="displayKey"
      @closeCategory="closeCategory"
      @selectCategory="selectCategory"
  />
</template>

<script>

import {computed, ref} from 'vue';
import {useRoute} from 'vue-router';

export default {
  name: 'CommonDeptMenu',
  props: {
    depth: Number,
    items: Array,
    valueKey: String,
    displayKey: String
  },
  emits: ['closeCategory', 'selectCategory'],
  setup(props, {emit}) {
    const route = useRoute();
    const selectedIndex = ref(-1);

    if (route.query[`cate${props.depth}`] > 0) {
      const queryCate = parseInt(route.query[`cate${props.depth}`]);
      const initIdx = props.items.map(x => x[props.valueKey]).indexOf(queryCate);
      if (initIdx > -1) {
        console.log('???');
        selectedIndex.value = initIdx;
      }
    }

    const selected = computed(() => {
      if (selectedIndex.value > -1 && props.items[selectedIndex.value]) {
        return props.items[selectedIndex.value];
      }
      return {};
    });

    const children = computed(() => {
      if (selectedIndex.value > -1 && props.items[selectedIndex.value] && props.items[selectedIndex.value].children) {
        return props.items[selectedIndex.value].children;
      }
      return [];
    });

    const closeCategory = () => {
      emit('closeCategory');
    }


    const selectCategory = arr => {
      emit('selectCategory', [props.items[selectedIndex.value][props.valueKey], ...arr]);
    }

    const selectOption = index => {
      if (selectedIndex.value === index) {
        selectedIndex.value = -1;
        emit('selectCategory', []);
      } else {
        selectedIndex.value = index
        const hasChildren = (selectedIndex.value > -1 && props.items[selectedIndex.value] && props.items[selectedIndex.value].children);
        emit('selectCategory', [props.items[selectedIndex.value][props.valueKey]])
        if (!hasChildren) {
          closeCategory();
        }
      }

    }

    return {
      selectedIndex,
      selected,
      children,
      selectOption,
      selectCategory,
      closeCategory
    }
  }
};
</script>

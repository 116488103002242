<template>
  <div class="header-snb kb-depth-category">
    <nav class="snb">
      <ul class="snb-list">
        <li class="snb-item">
          <div class="dropdown">
            <a href="javascript:" class="snb-link" :class="{'is-active': selectedIndex === -1}" @click.stop="selectOption(-1)">
              <span class="snb-text text-center">전체</span>
            </a>
          </div>
        </li>

        <li v-for="(item, index) in items" class="snb-item" :key="index">
          <div class="dropdown" :class="{'is-active': index === selectedIndex && toggle}">
            <a href="javascript:" class="snb-link" :class="{'is-active': index === selectedIndex}" @click.stop="selectOption(index)">
              <span class="snb-text text-center">{{ item.crseDispCateNm }}</span>
            </a>
            <div v-if="children && children.length > 0" class="dropdown-target dropdown-depthmemu">
              <div class="dropdown-box">
                <div class="depthmenu" data-depth="1">
                  <CommonDeptMenu
                      :depth="2"
                      :items="children"
                      :value-key="valueKey"
                      :display-key="displayKey"
                      @closeCategory="closeCategory"
                      @selectCategory="selectCategory"
                  />
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>

import CommonDeptMenu from '@/components/common/CommonDeptMenu';
import {computed, ref} from 'vue';
import {useRoute} from 'vue-router';
export default {
  name: 'CommonDeptCategory',
  components: {CommonDeptMenu},
  props: {
    toggle: Boolean,
    items: Array,
    valueKey: String,
    displayKey: String,
    exclusives: Array
  },
  emits: ['update:toggle', 'selectCategory'],
  setup(props, {emit}) {
    const route = useRoute();
    const selectedIndex = ref(-1);

    if (route.query.cate1 > 0) {
      const queryCate = parseInt(route.query.cate1);
      const initIdx = props.items.map(x => x[props.valueKey]).indexOf(queryCate);
      if (initIdx > -1) {
        selectedIndex.value = initIdx;
      }
    }

    const openMenu = ref(false);

    const children = computed(() => {
      if (selectedIndex.value > -1 && props.items[selectedIndex.value] && props.items[selectedIndex.value].children) {
        return props.items[selectedIndex.value].children;
      }
      return [];
    });

    const selectOption = index => {
      if (selectedIndex.value !== index) {
        if (index === -1) {
          selectedIndex.value = index;
          emit('selectCategory', []);
        } else {
          selectedIndex.value = index;
          emit('selectCategory', [props.items[selectedIndex.value][props.valueKey]])
        }
      }
      emit('update:toggle', true);
    }

    const closeCategory = () => {
      emit('update:toggle', false);
    }

    const selectCategory = arr => {
      emit('selectCategory', [props.items[selectedIndex.value][props.valueKey], ...arr])
    }

    return {
      openMenu,
      selectedIndex,
      children,
      selectOption,
      closeCategory,
      selectCategory
    }
  }
};
</script>

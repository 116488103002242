<template>
  <div class="popup-container is-active"><!-- popup -->
    <div class="popup" id="kb-talentboard-learner"><!-- popup > popup-inner -->
<!--      <div class="popup-inner">-->
        <div><!-- popup-inner > popup-content -->
          <div class="popup-content">
            <TalentJourney
                v-if="readies.learn && readies.license"
                :learns="learns"
                :license-codes="licenseCodes"
                :goals="[]"
                :is-opened="true"
            />
<!--            <div class="popup-buttons">-->
<!--              <button class="kb-btn kb-btn-outline kb-btn-lg"><span class="text">닫기</span></button>-->
<!--              <button class="kb-btn kb-btn-primary kb-btn-lg"><span class="text">확인</span></button>-->
<!--            </div>-->
          </div>
<!--        </div>&lt;!&ndash; popup > popup-close &ndash;&gt;-->
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TalentJourney from '@/components/mypage/talent/TalentJourney';
import {talentJourneyModalSetup} from '@/assets/js/modules/mypage/talent/talent-setup';

export default {
  name: 'TalentJourneyModal',
  components: {TalentJourney},
  props: {
    toggle: Boolean
  },
  emits: ['update:toggle'],
  setup: talentJourneyModalSetup
};
</script>

<template>
  <main class="kb-main" id="kb-educourse" @click="closeToggles(toggles)">
    <!-- main-header -->
    <template v-if="isMobile">
      <LxpMobileHeader :title="titleText">
        <template v-slot:left>
          <template v-if="isSearch">
            <div class="util util-back">
              <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
                <i class="icon-history-back"></i>
              </a>
            </div>
            <div class="util util-search-input">
              <input type="text" placeholder="연수신청 검색" v-model="search.crseNm" @keyup.enter="toggleSearch">
            </div>
          </template>
          <template v-else-if="isJourney">
            <div class="util util-back">
              <a href="javascript:" class="util-actions util-actions-back" @click="isJourney = false">
                <i class="icon-history-back"></i>
              </a>
            </div>
          </template>
          <template v-else>
<!--            <div class="util util-back">-->
<!--              <a href="javascript:" class="util-actions util-actions-back" @click="goBack">-->
<!--                <i class="icon-history-back"></i>-->
<!--              </a>-->
<!--            </div>-->
            <div class="util util-more">
              <a href="javascript:" class="util-actions util-actions-search" @click="headerToggle">
                <i class="icon-more"></i>
              </a>
              <div class="dropdown" :class="{'is-active': hToggle}" data-offset="header-left">
                <div class="dropdown-target">
                  <div class="dropdown-box">
                    <ul class="dropdown-option-list">
                      <li class="dropdown-option-item">
                        <file-down-link :file-key="fileDownKey" :add-link-class="['dropdown-option-link']" :add-text-class="['dropdown-option-text']"/>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-slot:right>
          <div class="util util-search">
            <a href="javascript:" class="util-actions util-actions-search" @click="toggleSearch">
              <i class="icon-search"></i>
            </a>
          </div>
        </template>
      </LxpMobileHeader>
      <!-- main-header > header-snb -->
      <div v-if="!isJourney" class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li class="snb-item">
              <button v-if="search.canAplyYn === 'N'" class="is-active" @click="toggleAplyCourseMobile">전체과정</button>
              <button v-if="search.canAplyYn !== 'N'" class="is-active" @click="toggleAplyCourseMobile">수강신청가능</button>
            </li>
            <li class="divider" style="display: contents">
              <hr>&nbsp;&nbsp;
            </li>
            <li class="snb-item">
              <SortButtonSelect3
                  v-model="search.cate1"
                  v-model:toggle="toggles.cate1"
                  title="전체"
                  :options="cates.cate1"
                  sequence-key="crseDispCateSn"
                  name-key="crseDispCateNm"
                  :is-all="true"
                  :is-num="true"
                  :width="200"
                  button-class="snb-link"
                  @update:toggle="closeToggles(toggles, 'cate1')"
                  @selected="selectCate(1)"
              />
            </li>
            <li class="snb-item">
              <SortButtonSelect3
                  v-model="search.cate2"
                  v-model:toggle="toggles.cate2"
                  title="전체"
                  :options="cates.cate2"
                  sequence-key="crseDispCateSn"
                  name-key="crseDispCateNm"
                  :is-all="true"
                  :is-num="true"
                  :width="200"
                  button-class="snb-link"
                  empty-text="카테고리 없음"
                  @update:toggle="closeToggles(toggles, 'cate2')"
                  @selected="selectCate(2)"
              />
            </li>
            <li class="snb-item">
              <SortButtonSelect3
                  v-model="search.cate3"
                  v-model:toggle="toggles.cate3"
                  title="전체"
                  :options="cates.cate3"
                  sequence-key="crseDispCateSn"
                  name-key="crseDispCateNm"
                  :is-all="true"
                  :is-num="true"
                  :width="200"
                  button-class="snb-link"
                  empty-text="카테고리 없음"
                  @update:toggle="closeToggles(toggles, 'cate3')"
              />
            </li>
          </ul>
        </nav>
      </div>
    </template>
    <lxp-main-header
        v-else
        :show-title="true"
        :show-breadcrumb="true"
        title="연수신청"
        :file-down-key="session.jbpsCd !=='A99' ? fileDownKey: null">

      <template v-slot:action>
        <CommonDeptCategory
            v-if="cates.cate1.length > 0"
            v-model:toggle="toggles.cate1"
            :items="cates.cate1"
            value-key="crseDispCateSn"
            display-key="crseDispCateNm"
            @selectCategory="selectArrCate"
        />
      </template>
    </lxp-main-header>

    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content main-component">
      <MobileTalentJourneyModal v-if="isMobile && isJourney" />
      <template v-else>
        <div  class="educourse-list-container">
          <!-- list-top -->
          <div class="list-top">
            <div class="top-column">
              <!-- list-top > list-sort -->
              <div class="sort" style="margin-right: 15px">
                <!--                <div class="kb-form-dropdown is-active">-->
                <SortButtonSelect3
                    v-if="isMobile"
                    v-model="search.orgnzCdDcd"
                    v-model:toggle="toggles.orgnzCdDcd"
                    title="주관처"
                    :options="orgnzCdOptions"
                    sequence-key="cd"
                    name-key="cdNm"
                    :is-all="true"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'orgnzCdDcd')"
                    @selected="pagingFunc()"
                />
                <SortButtonSelect
                    v-else
                    v-model="search.orgnzCdDcd"
                    v-model:toggle="toggles.orgnzCdDcd"
                    title="주관처"
                    :options="orgnzCdOptions"
                    sequence-key="cd"
                    name-key="cdNm"
                    :is-all="true"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'orgnzCdDcd')"
                    @selected="pagingFunc()"
                />
              </div>
              <div class="sort" style="margin-right: 15px">
                <SortButtonSelect3
                    v-if="isMobile"
                    v-model="search.mediaCateCd"
                    v-model:toggle="toggles.mediaCateCd"
                    title="매체"
                    :options="cateOptions"
                    sequence-key="mediaCateCd"
                    name-key="mediaCateNm"
                    :is-all="true"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'mediaCateCd')"
                    @selected="pagingFunc()"
                />
                <SortButtonSelect
                    v-else
                    v-model="search.mediaCateCd"
                    v-model:toggle="toggles.mediaCateCd"
                    title="매체"
                    :options="cateOptions"
                    sequence-key="mediaCateCd"
                    name-key="mediaCateNm"
                    :is-all="true"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'mediaCateCd')"
                    @selected="pagingFunc()"
                />
              </div>
              <div class="sort" style="margin-right: 25px">
                <SortButtonSelect3
                    v-if="isMobile"
                    v-model="search.likeYn"
                    v-model:toggle="toggles.likeYn"
                    :options="[{key: '', name: '최신순'}]"
                    sequence-key="key"
                    name-key="name"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'likeYn')"
                    @selected="pagingFunc()"
                />
                <SortButtonSelect
                    v-else
                    v-model="search.likeYn"
                    v-model:toggle="toggles.likeYn"
                    :options="[{key: '', name: '최신순'}]"
                    sequence-key="key"
                    name-key="name"
                    :width="200"
                    @update:toggle="closeToggles(toggles, 'likeYn')"
                    @selected="pagingFunc()"
                />
              </div>
              <!-- list-top > list-tab -->
              <div v-if="!isMobile" class="list-tab">
                <a href="javascript:" class="tab" :class="{'is-active' : search.canAplyYn === 'N'}"
                   @click="toggleAplyCourse('N')">전체과정 보기</a>
                <a href="javascript:" class="tab" :class="{'is-active' : search.canAplyYn !== 'N'}"
                   @click="toggleAplyCourse('')">수강신청가능과정 보기</a>
              </div>
            </div>
            <div v-if="!isMobile" class="top-column">
              <div>
                <div class="kb-form-search">
                  <div class="kb-form-search-status">
                    <span class="text">총 {{ paging.totalCount }}건</span>
                  </div>
                  <div class="kb-form-search-field">
                    <input v-model="search.crseNm" type="text" class="kb-form-search-input" placeholder="과정 검색"
                           @keyup.enter="pagingFunc()">
                    <button class="kb-form-search-btn" @click="pagingFunc()"><i class="icon-search">검색</i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ApplyCourse
              v-if="searchCnt > 0"
              :items="items"
              @toggleLike="actLike"
              :canAplyYn="search.canAplyYn"
              :pageNo="paging.pageNo ? paging.pageNo : 1"
              :dtlItem="dtlItem"
          />
        </div>
        <div v-if="searchCnt > 0" class="pt-5">
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc" :pageListSize="isMobile ? 5 : 10"/>
        </div>
      </template>
      <div style="margin-bottom: 30px"></div>

    </div>

    <!-- //main-content -->
    <TalentJourneyModal
        v-if="!isMobile && isJourney"
        v-model:toggle="isJourney"
    />
  </main>

</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, reactive, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import ApplyCourse from '@/components/apply/ApplyCourse';
import {ACT_GET_CRSE_APPLY_LIST, ACT_GET_CRSE_DISP_CATE_LIST, ACT_GET_DIGITAL_DTL} from '@/store/modules/course/course';
import {
  closeToggles,
  getDcdOptions,
  getListFunc,
  getFunc,
  getValueByQuery,
  pushQuery,
  setFilterByQuery,
} from '@/assets/js/ui.init';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {insertCourseAct} from '@/assets/js/modules/course/course-common';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {getCheckItems} from '@/assets/js/util';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import navigationUtils from '@/assets/js/navigationUtils';
import SortButtonSelect3 from '@/components/common/SortButtonSelect3';
import CommonDeptCategory from '@/components/common/CommonDeptCategory';
import TalentJourneyModal from '@/components/mypage/talent/TalentJourneyModal';
import MobileTalentJourneyModal from '@/components/mypage/talent/mobile/MobileTalentJourneyModal';
import FileDownLink from "@/components/common/FileDownLink";
import router from "@/router";

export default {
  name: 'ApplyTrain',
  components: {
    MobileTalentJourneyModal,
    TalentJourneyModal,
    CommonDeptCategory,
    SortButtonSelect3,
    SortButtonSelect,
    CommonPaginationFront,
    ApplyCourse,
    LxpMainHeader,
    LxpMobileHeader,
    FileDownLink,
  },
  setup() {

    const isMobile = navigationUtils.any();
    const store = useStore();
    const route = useRoute();
    const {showConfirm} = useAlert();
    const hToggle = ref(false);
    const isSearch = ref(false);
    const isJourney = ref(false);
    const searchCnt = ref(0);
    // const fileDownKeyList = ref([{'fileKey':'AnnualCourseList'},{'fileKey':'JMCERT_2023_manual'}]);
    const fileDownKey = ref('JMCERT_2023_manual');
    const session = computed(() => store.state.auth.session);
    // setTimeout(() => {
    //   isJourney.value = true;
    // }, 3000)

    const titleText = computed(() => {
      if(isSearch.value) {
        return '연수신청'.concat(' ',paging.totalCount,'건')
      } else if (isJourney.value) {
        return '역할별 Learning Journey'
      } else {
        return '';
      }
    })

    const goBack = () => {
      router.go(-1);
    }

    const items = ref([]);
    const paging = reactive({
      pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
      pageSize: 10,
      totalCount: 0,
    });

    const search = reactive({
      crseMstSn: 0,
      crseNm: '',
      orgnzCdDcd: '',
      mediaCateCd: '',
      likeYn: '',
      aplyRegYn: '',
      canAplyYn: '',
      befCrseCd: '',
      annualYn: '',
      cate1: 0,
      cate2: 0,
      cate3: 0,
    });

    const resetParams = () => {
      search.crseMstSn = 0;
      search.crseNm = '';
      search.orgnzCdDcd = '';
      search.mediaCateCd = '';
      search.likeYn = '';
      search.aplyRegYn = '';
      search.canAplyYn = '';
      search.annualYn = '';
      search.befCrseCd = '';
      search.cate1 = 0;
      search.cate2 = 0;
      search.cate3 = 0;
    };

    const dtlItem = reactive({
      lrnerId: 0,
      reqYn: '',
      digiDeptTyDcd: '',
      digiDeptTyNm: '',
      preYn: '',
      basicYn: '',
      advnYn: '',
    });

    const cates = reactive({
      cate1: [],
      cate2: computed(() => {
        if (search.cate1 > 0) {
          const target = cates.cate1.find(x => x.crseDispCateSn === search.cate1);
          if (target && target.children) {
            return target.children;
          }
        }
        return [];
      }),
      cate3: computed(() => {
        if (search.cate2 > 0) {
          const target = cates.cate2.find(x => x.crseDispCateSn === search.cate2);
          if (target && target.children) {
            return target.children;
          }
        }
        return [];
      }),
    });

    const toggles = reactive({
      orgnzCdDcd: false,
      mediaCateCd: false,
      likeYn: false,

      cate1: false,
      cate2: false,
      cate3: false,
    });

    const orgnzCdOptions = computed(() => getDcdOptions('2016'));
    const cateOptions = computed(() => store.state.code.cates);

    setFilterByQuery(search, route.query, {cate1: true, cate2: true, cate3: true});

    const mutItems = () => {
      items.value = items.value.map(x => ({...x, isLike: (x.myLikeCnt > 0)}));
      searchCnt.value = items.value.length;
    };

    const getCourseDisplayCateList = () => {
      let jbpsCd = session.value.jbpsCd ==='A99'? session.value.jbpsCd : '';
      store.dispatch(`course/${ACT_GET_CRSE_DISP_CATE_LIST}`,{jbpsCd:jbpsCd}).then(res => {

        if(session.value.jbpsCd === 'A99'){ //
          cates.cate1 =   getCheckItems(res).filter(x =>( x.crseDispCateSn === 4 ||x.crseDispCateSn === 3 ));
        }else{
          cates.cate1 = getCheckItems(res);
        }
      });
    };

    const getCourseApplyList = () => {
      let jbpsCd = session.value.jbpsCd ==='A99'? session.value.jbpsCd : '';
      getListFunc(`course/${ACT_GET_CRSE_APPLY_LIST}`, {...search, ...paging, jbpsCd: jbpsCd   ,aplyRegYn: 'Y', yearRangeYn: 'Y', annualYn: ''}, items,
          paging, mutItems);

    };

    const getCourseDigital = () => {
      getFunc(`course/${ACT_GET_DIGITAL_DTL}`, {}, dtlItem, () => {});
    };

    const actLike = (index) => {
      // 좋아요 상태이면 confirm 창 노출
      if (items.value[index].isLike) {
        showConfirm({
          text: `찜을 취소하시겠습니까?`,
          callback: () => {
            items.value[index].isLike = !items.value[index].isLike;
            insertCourseAct(actTyCdDcds.ACT_TY_CD_CANCEL_LIKE, {crseMstSn: items.value[index].crseMstSn});
          },
        });
      } else {
        items.value[index].isLike = !items.value[index].isLike;
        insertCourseAct(actTyCdDcds.ACT_TY_CD_LIKE, {crseMstSn: items.value[index].crseMstSn});

      }
    };

    const pagingFunc = (pageNo) => {
      if (pageNo) {
        pushQuery({...search, pageNo});
      } else {
        pushQuery({...search});
      }
    };

    const selectCate = (level) => {
      if (level === 1) {
        search.cate2 = 0;
        search.cate3 = 0;
      } else if (level === 2) {
        search.cate3 = 0;
      }
      pagingFunc();
    };

    const selectArrCate = arr => {

      for(let i = 0; i < arr.length; i++) {
        search[`cate${i + 1}`] = arr[i];
      }

      for(let i = 0; i < 3 - arr.length; i++) {
        search[`cate${(3 - i)}`] = 0;
      }

      pagingFunc();
    }


    const toggleAplyCourse = (canAplyYn) => {
      search.canAplyYn = canAplyYn;
      pushQuery({...search});
    };

    const headerToggle = () => {
      if (hToggle.value) {
        hToggle.value = false;
      } else {
        hToggle.value = true;
      }
    };

    const toggleSearch = () => {
      if (isSearch.value) {
        pagingFunc();
      } else {
        resetParams();
        isSearch.value = true;
        searchCnt.value = 0;
      }
    };

    const clickBack = () => {
      isSearch.value = false;
      hToggle.value = false;
      resetParams();
      if(Object.keys(route.query).length === 0) getCourseApplyList();
      else pushQuery({...search});
    };

    const toggleAplyCourseMobile = () => {
      if (search.canAplyYn === '') {
        search.canAplyYn = 'N';
        toggleAplyCourse('N');
      } else {
        search.canAplyYn = '';
        toggleAplyCourse('');
      }
    };

    let pagingDebounce = 0;

    watch(() => route.query, () => {
      if (route.name === 'ApplyTrain') {
        setFilterByQuery(search, route.query, {cate1: true, cate2: true, cate3: true});
        paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);

        if (pagingDebounce) clearTimeout(pagingDebounce);
        pagingDebounce = setTimeout(() => {
          getCourseApplyList();
        }, 500);
      }
    });

    getCourseDisplayCateList();
    getCourseApplyList();
    getCourseDigital();

    return {
      items,
      paging,
      search,
      toggles,


      orgnzCdOptions,
      cateOptions,
      cates,

      toggleAplyCourse,
      pagingFunc,
      selectCate,
      selectArrCate,
      closeToggles,
      actLike,
      isMobile,
      hToggle,
      headerToggle,
      isSearch,
      toggleSearch,
      clickBack,
      searchCnt,
      toggleAplyCourseMobile,
      fileDownKey,
      session,
      isJourney,
      titleText,
      dtlItem,
      goBack,
    };

  },
};
</script>

<template>
  <article class="content-section content-section-card section-journey kb-collapse" :class="{'is-active' : journey.toggle}">
    <div v-if="!isOpened" class="card-header kb-collapse-toggle" @click="journey.toggle=!journey.toggle">
      <h4 class="title">
        역할별 Learning Journey
        <button class="tool-tip-button" @click.stop="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button>
      </h4>
      <i class="icon-arrow"></i>
    </div>
    <div class="tool-tip" :class="{'is-active': tooltip}" style="top:-30px">
      <div class="tool-tip-desc">
        8가지 미래성장분야에 대한 학습성장 로드맵의 준비정도를 시각화하여 학습자의 CDP를 지원합니다. <br>
        ❶ KB ACE Academy, ❷ 직무선택과정, ❸ 등급자격증의 달성 여부 확인이 가능하며, 해당 연수과정 클릭 시 연수신청 화면으로 이동합니다.

      </div>
    </div>
    <div class="kb-collapse-target">
      <div class="learning-journeys-container">
        <div class="learning-header">
          <button class="kb-btn kb-btn-category" @click="goNext"><span class="text">{{ currentTalent.name }}</span><i class="icon-arrow"></i></button>
        </div>

        <div class="content-scrollwrap">
          <div class="learning-table" :class="{'learning-table-dt': isDt()}">
            <div class="learning-table-level">
              <span class="level">Beginner</span>
              <span class="level">Expert</span>
            </div>
            <table v-for="(table, index) in gridElements" :key="index">
              <template v-for="(element, idx) in table" :key="`${index}-${idx}`">
                <colgroup v-if="element.type === 'colgroup'">
                  <col v-for="(item, i) in element.items" :style="{width: item}" :key="`${index}-${idx}-header-${i}`">
                </colgroup>
                <thead v-else-if="element.type === 'header'">
                <tr v-for="(item, i) in element.items" :key="`${index}-${idx}-header-${i}`">
                  <th v-for="(el, j) in item" :class="el.class" :rowspan="getDefaultSpanValue(el.rowspan)" :colspan="getDefaultSpanValue(el.colspan)" :key="`${index}-${idx}-header-${i}-${j}`">
                    <div v-if="el.blank" class="cell cell-none" :class="[el.divClass]">&nbsp;</div>
                    <div v-else class="cell kb-cell-text-center" :class="[el.divClass]">
                      <span class="cell-label kb-cell-text-bold kb-cell-text-center">{{ el.name }}</span>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody v-else-if="element.type === 'body'" :class="element.class">
                <tr v-for="(item, i) in element.items" :key="`${index}-${idx}-body-${i}`">
                  <template v-for="(el, j) in item" :key="`${index}-${idx}-body-${i}-${j}`">
                    <th v-if="el.type === 'th'" :class="el.class" :rowspan="getDefaultSpanValue(el.rowspan)" :colspan="getDefaultSpanValue(el.colspan)" >
                      <div v-if="el.blank" class="cell cell-none" :class="[el.divClass]"><span class="cell-label">&nbsp;</span></div>
                      <template v-else>
                        <div v-if="el.license" class="cell" :class="[isGet(el) && 'cell-complete']">
                          <div v-if="isGet(el)" class="cell-badge badge"><span class="badge-text">취득</span></div>
                          <span class="cell-label">{{ el.name }}</span>
                        </div>
                        <div v-else class="cell kb-mouse-cursor" :class="[isFinish(el) && 'cell-complete']" @click="goToApply(el)">
                          <div v-if="isFinish(el)" class="cell-badge badge"><span class="badge-text">수료</span></div>
                          <span class="cell-label">{{ el.name }}</span>
                        </div>
                      </template>
                    </th>
                    <td v-else :class="el.class" :rowspan="getDefaultSpanValue(el.rowspan)" :colspan="getDefaultSpanValue(el.colspan)">
                      <div v-if="el.blank" class="cell cell-none" :class="[el.divClass]"><span class="cell-label">&nbsp;</span></div>
                      <template v-else>
                        <div v-if="el.license" class="cell" :class="[isGet(el) && 'cell-complete']">
                          <div v-if="isGet(el)" class="cell-badge badge"><span class="badge-text">취득</span></div>
                          <span class="cell-label">{{ el.name }}</span>
                        </div>
                        <div v-else class="cell kb-mouse-cursor" :class="[isFinish(el) && 'cell-complete']" @click="goToApply(el)">
                          <div v-if="isFinish(el)" class="cell-badge badge"><span class="badge-text">수료</span></div>
                          <span class="cell-label">{{ el.name }}</span>
                        </div>
                      </template>
                    </td>
                  </template>
                </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {talentJourneySetup} from '@/assets/js/modules/mypage/talent/talent-setup';

export default {
  name: "MobileTalentJourney",
  components: {},
  props: {
    learns: Array,
    licenseCodes: Array,
    goals: Array,
    isOpened: Boolean,
  },
  setup: talentJourneySetup
}
</script>

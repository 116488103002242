<template>
  <div id="kb-talentboard-learner">
    <MobileTalentJourney
        v-if="readies.learn && readies.license"
        :learns="learns"
        :license-codes="licenseCodes"
        :goals="[]"
        :is-opened="true"
    />
  </div>
</template>

<script>
import {talentJourneyModalSetup} from '@/assets/js/modules/mypage/talent/talent-setup';
import MobileTalentJourney from '@/components/mypage/talent/mobile/MobileTalentJourney';

export default {
  name: "MobileTalentJourneyModal",
  components: {MobileTalentJourney},
  setup: talentJourneyModalSetup
}
</script>
